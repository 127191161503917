$platform-name: 'moneyadvicescotland';

$brand-primary: #7665A0;
$brand-secondary: $brand-primary;

$black: #000;
$grey-dark: #333;
$grey-light: #BBC8C5; // This is their 'silver' brand colour
$grey-lighter: #f1f6f7;
$white: #fff;

$container-max-width: 1330px;
$gap-width: 40px;
$border-colour: darken($grey-lighter, 10%);

$spacer: 1.3rem;

$logo: "logo_reverse.svg"; // "logo.png";
// $logo-retina: "logo_reverse@2x.png"; // "logo@2x.png";
$logo-width: 270px;
$logo-height: 65px;
$logo-absolute: true;

$font-family-base: arial, sans-serif;
$font-size-base: 1.2rem;

$headings-font-family: din-condensed, sans-serif;
$headings-font-weight: 400;
$headings-colour: $brand-primary;
$headings-letter-spacing: -.04em;

$btn-padding-x: 1.2em;
$btn-padding-y: .6em;
$btn-font-family: $headings-font-family;
$btn-text-transform: uppercase;
$btn-box-shadow: 0 10px 20px rgba(0,0,0,.07);

$menu-admin-enabled: false;

$header-background-colour: $brand-primary;
// $header-background-colour: transparent;
$header-content-max-width: $container-max-width + 200px;
$header-content-margin-top: 0;
$header-content-margin-bottom: $header-content-margin-top;
$header-content-padding-top: 8px;

$cta-btn-background-colour: $white;
$cta-btn-colour: $brand-primary;

$header-search-input-max-width: 165px;
$header-search-input-padding: .375rem 0;
$header-search-input-placeholder-colour: rgba($white, .4);
$header-search-input-border-colour: rgba($white, .6);
$header-search-input-border-width: 0 0 2px 0;
$header-search-input-background-colour: transparent;
$header-search-input-colour: $white;
$header-search-input-focus-background-colour: transparent;
$header-search-input-focus-colour: $white;

$header-search-button-width: 30px;
$header-search-button-background-colour: transparent;
$header-search-button-icon-font-size: 1rem;
$header-search-button-icon-colour: $white;

$nav-search-input-border-width: 0 0 2px 0;
$nav-search-button-icon-colour: $white;

$nav-background-colour: transparent;

$nav-top-level-item-padding: 10px 14px;
$nav-top-level-item-colour: $white;
$nav-top-level-item-font-size: 1.05rem;
$nav-top-level-item-font-weight: 600;
$nav-top-level-item-hover-background-colour: darken($brand-primary, 4%);
$nav-top-level-chevrons-enabled: true;
$nav-top-level-chevrons-colour: rgba($white, .4);

$nav-submenu-background-colour: $nav-top-level-item-hover-background-colour;
$nav-submenu-item-padding: 8px 14px;
$nav-submenu-item-colour: $white;
$nav-submenu-item-font-size: .95rem;
$nav-submenu-chevrons-colour: $nav-top-level-chevrons-colour;

$burger-btn-background-colour: darken($brand-primary, 10%);

$nav-normal-contents-max-width: $header-content-max-width;
$nav-normal-align-items: right;
$nav-normal-mobile-background-colour: darken($brand-primary, 2.5%);

$home-intro-background-colour: transparent;
$home-intro-colour: $white;
$home-intro-margin-top: 0;
$home-intro-text-align: left;
$home-intro-font-size: 5rem;
$home-intro-line-height: .9;

$carousel-details-background-colour: $grey-lighter;
$carousel-details-box-shadow: 0 14px 28px rgba(0,0,0,0.1), 0 10px 10px rgba(0,0,0,0.05);
$carousel-details-padding: 24px;
$carousel-details-position-x: right;
$carousel-details-max-width: $container-max-width * .66;

$carousel-heading-font-size: 2.5em;
$carousel-summary-font-size: 1.25em;
$carousel-summary-colour: $grey-dark;
$carousel-read-more-font-size: 1rem;

$carousel-buttons-enabled: false;
$carousel-controls-button-size: 30px;
$carousel-controls-icon-font-size: .85rem;

$carousel-mobile-details-background-colour: $carousel-details-background-colour;
$carousel-mobile-heading-colour: $brand-primary;
$carousel-mobile-summary-colour: $carousel-summary-colour;

$banner-min-height: 0; // This isn't really compatible with the whole hiding the banner image thing

$home-features-background-colour: transparent;
$home-features-cards-entry-animation-prefab: 1;

$impact-stats-padding-y: 0;
$impact-stats-heading-font-size: 2em;
$impact-stats-heading-margin-bottom: $spacer * 1.5;
$impact-stats-figure-font-size: 5em;
$impact-stats-figure-colour: $brand-primary;
$impact-stats-summary-font-size: 1.2rem;

$home-feeds-background-colour: transparent;

// $feed-item-event-start-date-enabled: false;
// $feed-item-event-location-enabled: false;

$card-border: 0;
$card-details-background-colour: $grey-lighter;
$card-heading-font-size: 1.5em;
$card-heading-margin-bottom: 5px;
$card-hover-details-background-colour: darken($card-details-background-colour, 5%);

$post-content-max-width: 980px;

$sidebar-enabled: false;
$sidebar-layout: above;
// $sidebar-adjacent-width: 320px;
// $sidebar-background-colour: $grey-lighter;
// $sidebar-padding: 24px;

$associated-most-read-enabled: false;
$associated-max-number-of-posts: 3;

$footer-prefab: 5;
$footer-padding-y: 3em;

$newsletter-background-colour: transparent;
$newsletter-heading-text-align: left;
// $newsletter-input-background-colour: $grey-lighter;

$breadcrumb-enabled: false;